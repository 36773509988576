// import { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
// import { ArrowLeft, Loader2 } from "lucide-react";
// import {
//   collection,
//   query,
//   where,
//   limit,
//   startAfter,
//   getDocs,
// } from "firebase/firestore";
// import { db } from "../config/firebase.js";

// const ITEMS_PER_PAGE = 12;

// export function ProductGrid({
//   selectedCategory,
//   categories,
//   subcategories,
//   handleCategorySelect,
//   handleBack,
// }) {
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [initialLoad, setInitialLoad] = useState(true);
//   const [hasMore, setHasMore] = useState(true);
//   const [error, setError] = useState(null);

//   const lastDocRef = useRef(null);
//   const observerRef = useRef(null);
//   const loadingRef = useRef(false);
//   const categoryRef = useRef(selectedCategory);

//   // Simple cache storing products and scroll positions per category
//   const categoryCache = useRef(new Map());

//   useEffect(() => {
//     if (categoryRef.current !== selectedCategory) {
//       // Save current category state before switching
//       if (products.length > 0) {
//         categoryCache.current.set(categoryRef.current, {
//           products,
//           scrollPosition: window.scrollY,
//           lastDoc: lastDocRef.current,
//           hasMore
//         });
//       }

//       // Check if we have cached data for the new category
//       const cachedData = categoryCache.current.get(selectedCategory);
//       if (cachedData) {
//         setProducts(cachedData.products);
//         lastDocRef.current = cachedData.lastDoc;
//         setHasMore(cachedData.hasMore);
//         setInitialLoad(false);
//         // Restore scroll position after state update
//         setTimeout(() => window.scrollTo(0, cachedData.scrollPosition), 0);
//       } else {
//         // Reset for new category
//         setProducts([]);
//         setInitialLoad(true);
//         setHasMore(true);
//         lastDocRef.current = null;
//         window.scrollTo(0, 0);
//       }

//       categoryRef.current = selectedCategory;
//     }
//   }, [selectedCategory]);

//   const fetchProducts = async (isInitial = false) => {
//     if (loadingRef.current || selectedCategory === "all") return;

//     try {
//       setLoading(true);
//       loadingRef.current = true;
//       setError(null);

//       const productsRef = collection(db, "products");
//       let baseQuery = query(
//         productsRef,
//         where("categoryId", "==", selectedCategory),
//         limit(ITEMS_PER_PAGE)
//       );

//       if (!isInitial && lastDocRef.current) {
//         baseQuery = query(
//           productsRef,
//           where("categoryId", "==", selectedCategory),
//           startAfter(lastDocRef.current),
//           limit(ITEMS_PER_PAGE)
//         );
//       }

//       const snapshot = await getDocs(baseQuery);

//       if (snapshot.empty) {
//         setHasMore(false);
//         if (isInitial) {
//           setProducts([]);
//         }
//         return;
//       }

//       const newProducts = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//         images: doc.data().images ? [doc.data().images[0]] : [],
//       }));

//       lastDocRef.current = snapshot.docs[snapshot.docs.length - 1];

//       if (isInitial) {
//         setProducts(newProducts);
//       } else {
//         setProducts(prev => [...prev, ...newProducts]);
//       }

//       setHasMore(snapshot.docs.length === ITEMS_PER_PAGE);

//     } catch (error) {
//       console.error("Error fetching products:", error);
//       setError("Failed to load products. Please try again.");
//     } finally {
//       setLoading(false);
//       loadingRef.current = false;
//       setInitialLoad(false);
//     }
//   };

//   // Initial products fetch
//   useEffect(() => {
//     if (selectedCategory === "all" || subcategories.length > 0) {
//       setProducts([]);
//       setInitialLoad(false);
//       return;
//     }

//     // Only fetch if we don't have cached data
//     if (!categoryCache.current.has(selectedCategory)) {
//       fetchProducts(true);
//     }
//   }, [selectedCategory, subcategories.length]);

//   // Intersection Observer setup
//   useEffect(() => {
//     if (!hasMore || loading || selectedCategory === "all" || subcategories.length > 0) {
//       return;
//     }

//     const observer = new IntersectionObserver(
//       (entries) => {
//         if (entries[0].isIntersecting && hasMore && !loadingRef.current) {
//           fetchProducts(false);
//         }
//       },
//       { threshold: 0.1 }
//     );

//     if (observerRef.current) {
//       observer.observe(observerRef.current);
//     }

//     return () => observer.disconnect();
//   }, [hasMore, loading, selectedCategory, subcategories.length]);

//   // Clean up old cache entries after 5 minutes
//   useEffect(() => {
//     const interval = setInterval(() => {
//       const now = Date.now();
//       for (const [key, value] of categoryCache.current.entries()) {
//         if (now - value.timestamp > 5 * 60 * 1000) {
//           categoryCache.current.delete(key);
//         }
//       }
//     }, 60000);

//     return () => clearInterval(interval);
//   }, []);

//   const renderSubcategories = () => {
//     if (subcategories.length === 0) return null;

//     return (
//       <div className="mb-12">
//         <h2 className="text-2xl font-semibold mb-6">Subcategories</h2>
//         <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
//           {subcategories
//             .sort((a, b) => (parseInt(a.numericId) || 0) - (parseInt(b.numericId) || 0))
//             .map((subcategory) => (
//               <div
//                 key={subcategory.id}
//                 onClick={() => handleCategorySelect(subcategory.id)}
//                 className="group cursor-pointer bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
//               >
//                 <div className="relative aspect-w-16 aspect-h-9">
//                   <img
//                     src={subcategory.imageUrl}
//                     alt={subcategory.name}
//                     className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-110"
//                   />
//                   <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 transition-opacity duration-300" />
//                 </div>
//                 <div className="p-4">
//                   <h3 className="text-lg font-semibold text-gray-900">
//                     {subcategory.name}
//                   </h3>
//                 </div>
//               </div>
//             ))}
//         </div>
//       </div>
//     );
//   };

//   const renderProducts = () => {
//     if (subcategories.length > 0) return null;

//     return (
//       <div className="mt-8">
//         <div className="flex justify-between items-center mb-6">
//           {/* <h2 className="text-2xl font-semibold">Products</h2> */}
//         </div>

//         {initialLoad ? (
//           <div className="flex items-center justify-center py-12">
//             <Loader2 className="h-8 w-8 animate-spin text-primary" />
//           </div>
//         ) : products.length === 0 && !loading ? (
//           <div className="text-center py-12">
//             <h3 className="text-xl text-gray-600">
//               No products found in this category
//             </h3>
//             <button
//               onClick={handleBack}
//               className="mt-4 inline-flex items-center gap-2 text-primary hover:text-primary/80"
//             >
//               <ArrowLeft className="h-4 w-4" />
//               Go back
//             </button>
//           </div>
//         ) : (
//           <>
//             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//               {products.map((product, index) => (
//                 <div
//                   key={product.id}
//                   className="bg-white rounded-xl overflow-hidden shadow-lg animate-fadeIn transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
//                   style={{ animationDelay: `${index * 0.1}s` }}
//                 >
//                   <div className="relative group">
//                     {product.images?.[0] && (
//                       <img
//                         src={product.images[0].url}
//                         alt={product.name}
//                         className="w-full h-72 object-contain transition-transform duration-300 group-hover:scale-110"
//                       />
//                     )}
//                     <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-300 group-hover:opacity-30" />
//                     <div className="absolute inset-0 flex items-center justify-center">
//                       <Link
//                         to={`/product/${product.id}`}
//                         className="bg-white text-primary px-6 py-2 rounded-full opacity-0 transform translate-y-4 transition-all duration-300 group-hover:opacity-100 group-hover:translate-y-0 hover:bg-primary hover:text-white"
//                       >
//                         Quick View
//                       </Link>
//                     </div>
//                   </div>
//                   <div className="p-6">
//                     <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
//                     {product.originalPrice && (
//                       <div className="flex items-center gap-2 text-sm">
//                         <span
//                           className={
//                             product.discountPercentage
//                               ? "text-black-500 line-through"
//                               : "text-primary font-semibold"
//                           }
//                         >
//                           ₹{product.originalPrice.toLocaleString("en-IN")}
//                         </span>
//                         {product.discountPercentage > 0 && (
//                           <span
//                           style={{color:'red'}}
//                           className="text-primary font-semibold">
//                             ₹{product.discountedPrice.toLocaleString("en-IN")}
//                           </span>
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               ))}
//             </div>

//             {hasMore && (
//               <div ref={observerRef} className="flex justify-center py-8">
//                 {loading && <Loader2 className="h-8 w-8 animate-spin text-primary" />}
//               </div>
//             )}
//           </>
//         )}
//       </div>
//     );
//   };

//   if (selectedCategory === "all") {
//     return (
//       <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
//         {categories
//           .sort((a, b) => (parseInt(a.numericId) || 0) - (parseInt(b.numericId) || 0))
//           .map((category, index) => (
//             <div
//               key={category.id}
//               onClick={() => handleCategorySelect(category.id)}
//               className="group cursor-pointer bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:-translate-y-2 hover:shadow-xl animate-fadeIn"
//               style={{ animationDelay: `${index * 0.1}s` }}
//             >
//               <div className="relative aspect-w-16 aspect-h-9">
//                 <img
//                   src={category.imageUrl}
//                   alt={category.name}
//                   className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-110"
//                 />
//                 <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 transition-opacity duration-300" />
//               </div>
//               <div className="p-4">
//                 <h3 className="text-lg font-semibold text-gray-900">
//                   {category.name}
//                 </h3>
//                 {category.numericId && (
//                   <span className="text-sm text-gray-500">
//                     #{category.numericId}
//                   </span>
//                 )}
//               </div>
//             </div>
//           ))}
//       </div>
//     );
//   }

//   return (
//     <div>
//       {error && (
//         <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative mb-6">
//           <strong className="font-bold">Error:</strong>
//           <span className="block sm:inline"> {error}</span>
//         </div>
//       )}
//       {renderSubcategories()}
//       {renderProducts()}
//     </div>
//   );
// }

import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft, Loader2 } from "lucide-react";
import {
  collection,
  query,
  where,
  limit,
  startAfter,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db } from "../config/firebase.js";

const ITEMS_PER_PAGE = 12;

export function ProductGrid({
  selectedCategory,
  categories,
  subcategories,
  handleCategorySelect,
  handleBack,
}) {
  // State Management
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);

  // Refs for managing async operations and caching
  const lastDocRef = useRef(null); // Stores last document for pagination
  const observerRef = useRef(null); // Intersection observer for infinite scroll
  const loadingRef = useRef(false); // Prevents concurrent fetches
  const categoryRef = useRef(selectedCategory); // Tracks category changes

  // Cache implementation for storing category data
  const categoryCache = useRef(new Map());

  // Category Change Handler
  useEffect(() => {
    if (categoryRef.current !== selectedCategory) {
      // Cache current category state before switching
      if (products.length > 0) {
        categoryCache.current.set(categoryRef.current, {
          products,
          scrollPosition: window.scrollY,
          lastDoc: lastDocRef.current,
          hasMore,
          timestamp: Date.now(),
        });
      }

      // Try to restore cached data for new category
      const cachedData = categoryCache.current.get(selectedCategory);
      if (cachedData) {
        // Restore cached state
        setProducts(cachedData.products);
        lastDocRef.current = cachedData.lastDoc;
        setHasMore(cachedData.hasMore);
        setInitialLoad(false);
        // Restore scroll position after state updates
        setTimeout(() => window.scrollTo(0, cachedData.scrollPosition), 0);
      } else {
        // Reset state for new category fetch
        setProducts([]);
        setInitialLoad(true);
        setHasMore(true);
        lastDocRef.current = null;
        window.scrollTo(0, 0);
      }

      categoryRef.current = selectedCategory;
    }
  }, [selectedCategory]);

  // Product Fetching Logic
  const fetchProducts = async (isInitial = false) => {
    if (loadingRef.current || selectedCategory === "all") return;
  
    try {
      setLoading(true);
      loadingRef.current = true;
      setError(null);
  
      const productsRef = collection(db, "products");
      
      // Base query with single orderBy to work with existing index
      let baseQuery;
      
      if (!isInitial && lastDocRef.current) {
        baseQuery = query(
          productsRef,
          where("categoryId", "==", selectedCategory),
          orderBy("sequenceId", "asc"),
          startAfter(lastDocRef.current),
          limit(ITEMS_PER_PAGE)
        );
      } else {
        baseQuery = query(
          productsRef,
          where("categoryId", "==", selectedCategory),
          orderBy("sequenceId", "asc"),
          limit(ITEMS_PER_PAGE)
        );
      }
  
      const snapshot = await getDocs(baseQuery);
  
      if (snapshot.empty) {
        setHasMore(false);
        if (isInitial) {
          setProducts([]);
        }
        return;
      }
  
      // Process and normalize product data
      const newProducts = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          // Handle potential missing or malformed data
          name: data.name || 'Unnamed Product',
          sequenceId: data.sequenceId || Number.MAX_SAFE_INTEGER, // Put items without sequenceId at the end
          images: data.images && data.images.length > 0 ? [data.images[0]] : [],
          originalPrice: data.originalPrice || 0,
          discountedPrice: data.discountedPrice || data.originalPrice || 0,
          discountPercentage: data.discountPercentage || 0,
        };
      }).sort((a, b) => {
        // Primary sort by sequenceId
        if (a.sequenceId !== b.sequenceId) {
          return a.sequenceId - b.sequenceId;
        }
        // Secondary sort by name for items with same sequenceId
        return a.name.localeCompare(b.name);
      });
  
      // Store last document for pagination
      lastDocRef.current = snapshot.docs[snapshot.docs.length - 1];
  
      // Update products state
      if (isInitial) {
        setProducts(newProducts);
      } else {
        setProducts(prev => {
          // Remove duplicates in case of race conditions
          const existingIds = new Set(prev.map(p => p.id));
          const uniqueNewProducts = newProducts.filter(p => !existingIds.has(p.id));
          return [...prev, ...uniqueNewProducts];
        });
      }
  
      // Check if more products might be available
      setHasMore(snapshot.docs.length === ITEMS_PER_PAGE);
  
    } catch (error) {
      console.error("Error fetching products:", error);
      
      // Handle specific error cases
      if (error.code === 'failed-precondition') {
        setError("Database index required. Please contact the administrator.");
      } else if (error.code === 'permission-denied') {
        setError("You don't have permission to access these products.");
      } else if (error.code === 'resource-exhausted') {
        setError("Too many requests. Please try again later.");
      } else {
        setError("Failed to load products. Please try again.");
      }
      
      // Reset state on error
      if (isInitial) {
        setProducts([]);
        setHasMore(false);
      }
    } finally {
      setLoading(false);
      loadingRef.current = false;
      setInitialLoad(false);
    }
  };

  // Initial Load Effect
  useEffect(() => {
    if (selectedCategory === "all" || subcategories.length > 0) {
      setProducts([]);
      setInitialLoad(false);
      return;
    }

    // Only fetch if no cached data exists
    if (!categoryCache.current.has(selectedCategory)) {
      fetchProducts(true);
    }
  }, [selectedCategory, subcategories.length]);

  // Infinite Scroll Implementation
  useEffect(() => {
    if (
      !hasMore ||
      loading ||
      selectedCategory === "all" ||
      subcategories.length > 0
    ) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loadingRef.current) {
          fetchProducts(false);
        }
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, [hasMore, loading, selectedCategory, subcategories.length]);

  // Cache Cleanup
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      for (const [key, value] of categoryCache.current.entries()) {
        if (now - value.timestamp > 5 * 60 * 1000) {
          // 5 minutes cache
          categoryCache.current.delete(key);
        }
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  // Render Methods
  const renderSubcategories = () => {
    if (subcategories.length === 0) return null;

    return (
      <div className="mb-12">
        <h2 className="text-2xl font-semibold mb-6">Subcategories</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {subcategories
            .sort(
              (a, b) =>
                (parseInt(a.numericId) || 0) - (parseInt(b.numericId) || 0)
            )
            .map((subcategory) => (
              <div
                key={subcategory.id}
                onClick={() => handleCategorySelect(subcategory.id)}
                className="group cursor-pointer bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
              >
                <div className="relative aspect-w-16 aspect-h-9">
                  <img
                    src={subcategory.imageUrl}
                    alt={subcategory.name}
                    className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 transition-opacity duration-300" />
                </div>
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {subcategory.name}
                  </h3>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderProducts = () => {
    if (subcategories.length > 0) return null;

    return (
      <div className="mt-8">
        <div className="flex justify-between items-center mb-6">
          {/* <h2 className="text-2xl font-semibold">Products</h2> */}
        </div>

        {initialLoad ? (
          <div className="flex items-center justify-center py-12">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
          </div>
        ) : products.length === 0 && !loading ? (
          <div className="text-center py-12">
            <h3 className="text-xl text-gray-600">
              No products found in this category
            </h3>
            <button
              onClick={handleBack}
              className="mt-4 inline-flex items-center gap-2 text-primary hover:text-primary/80"
            >
              <ArrowLeft className="h-4 w-4" />
              Go back
            </button>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {products.map((product, index) => (
                <div
                  key={product.id}
                  className="bg-white rounded-xl overflow-hidden shadow-lg animate-fadeIn transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  <div className="relative group">
                    {product.images?.[0] && (
                      <img
                        src={product.images[0].url}
                        alt={product.name}
                        className="w-full h-72 object-contain transition-transform duration-300 group-hover:scale-110"
                      />
                    )}
                    <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-300 group-hover:opacity-30" />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Link
                        to={`/product/${product.id}`}
                        className="bg-white text-primary px-6 py-2 rounded-full opacity-0 transform translate-y-4 transition-all duration-300 group-hover:opacity-100 group-hover:translate-y-0 hover:bg-primary hover:text-white"
                      >
                        Quick View
                      </Link>
                    </div>
                  </div>
                  <div className="p-6">
                    <h3 className="text-xl font-semibold mb-2">
                      {product.name}
                    </h3>
                    {product.originalPrice && (
                      <div className="flex items-center gap-2 text-sm">
                        <span
                          className={
                            product.discountPercentage
                              ? "text-black-500 line-through"
                              : "text-primary font-semibold"
                          }
                        >
                          ₹{product.originalPrice.toLocaleString("en-IN")}
                        </span>
                        {product.discountPercentage > 0 && (
                          <span
                            style={{ color: "red" }}
                            className="text-primary font-semibold"
                          >
                            ₹{product.discountedPrice.toLocaleString("en-IN")}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {hasMore && (
              <div ref={observerRef} className="flex justify-center py-8">
                {loading && (
                  <Loader2 className="h-8 w-8 animate-spin text-primary" />
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  // Main Render Logic
  if (selectedCategory === "all") {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {categories
          .sort(
            (a, b) =>
              (parseInt(a.numericId) || 0) - (parseInt(b.numericId) || 0)
          )
          .map((category, index) => (
            <div
              key={category.id}
              onClick={() => handleCategorySelect(category.id)}
              className="group cursor-pointer bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:-translate-y-2 hover:shadow-xl animate-fadeIn"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="relative aspect-w-16 aspect-h-9">
                <img
                  src={category.imageUrl}
                  alt={category.name}
                  className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 transition-opacity duration-300" />
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-900">
                  {category.name}
                </h3>
                {category.numericId && (
                  <span className="text-sm text-gray-500">
                    #{category.numericId}
                  </span>
                )}
              </div>
            </div>
          ))}
      </div>
    );
  }

  return (
    <div>
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative mb-6">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      {renderSubcategories()}
      {renderProducts()}
    </div>
  );
}
