import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronDown, X, Menu, ChevronRight } from "lucide-react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";
import logo from "../images/logo.png";

const NAV_ITEMS = ["Home", "About", "Testimonials", "Contact"];
const CACHE_KEY = "navigation_categories";
const CACHE_DURATION = 1000 * 60 * 5; 

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubCategory, setActiveSubCategory] = useState(null);
  const [categoriesData, setCategoriesData] = useState({
    mainCategories: [],
    subCategories: {},
    level3Categories: {},
  });
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  // Cache management functions
  const getCachedData = () => {
    try {
      const cached = localStorage.getItem(CACHE_KEY);
      if (!cached) return null;

      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp > CACHE_DURATION) {
        localStorage.removeItem(CACHE_KEY);
        return null;
      }

      return data;
    } catch (error) {
      console.error("Cache error:", error);
      localStorage.removeItem(CACHE_KEY);
      return null;
    }
  };

  const setCacheData = (data) => {
    try {
      localStorage.setItem(
        CACHE_KEY,
        JSON.stringify({
          data,
          timestamp: Date.now(),
        })
      );
    } catch (error) {
      console.error("Cache set error:", error);
    }
  };

  // Handle category click
  const handleCategoryClick = (categoryId, event) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveCategory(activeCategory === categoryId ? null : categoryId);
    setActiveSubCategory(null); // Reset subcategory when changing category
  };

  // Handle subcategory click
  const handleSubCategoryClick = (subCategoryId, event) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveSubCategory(
      activeSubCategory === subCategoryId ? null : subCategoryId
    );
  };

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".category-menu-container")) {
        setActiveCategory(null);
        setActiveSubCategory(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const cachedData = getCachedData();
      if (cachedData) {
        setCategoriesData(cachedData);
        setLoading(false);
        return;
      }

      const snapshot = await getDocs(collection(db, "categories"));
      const allCategories = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const organized = {
        mainCategories: [],
        subCategories: {},
        level3Categories: {},
      };

      // First pass: Identify main categories
      allCategories.forEach((cat) => {
        if (!cat.parentId) {
          organized.mainCategories.push(cat);
        }
      });

      // Second pass: Organize sub and level3 categories
      allCategories.forEach((cat) => {
        if (cat.parentId) {
          const parent = allCategories.find((p) => p.id === cat.parentId);
          if (parent && !parent.parentId) {
            // This is a subcategory
            if (!organized.subCategories[parent.id]) {
              organized.subCategories[parent.id] = [];
            }
            organized.subCategories[parent.id].push(cat);
          } else if (parent && parent.parentId) {
            // This is a level 3 category
            if (!organized.level3Categories[parent.id]) {
              organized.level3Categories[parent.id] = [];
            }
            organized.level3Categories[parent.id].push(cat);
          }
        }
      });

      // Sort all arrays by numericId
      const sortByNumericId = (a, b) => {
        return (parseInt(a.numericId) || 0) - (parseInt(b.numericId) || 0);
      };

      organized.mainCategories.sort(sortByNumericId);
      Object.values(organized.subCategories).forEach((arr) =>
        arr.sort(sortByNumericId)
      );
      Object.values(organized.level3Categories).forEach((arr) =>
        arr.sort(sortByNumericId)
      );

      setCategoriesData(organized);
      setCacheData(organized);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
      const cachedData = getCachedData();
      if (cachedData) {
        setCategoriesData(cachedData);
      }
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    let timeoutId;
    const handleScroll = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsScrolled(window.scrollY > 20);
      }, 100);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? "hidden" : "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMobileMenuOpen]);

  const toggleExpanded = useCallback((id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  const CategoryDropdown = useMemo(() => {
    return ({ category }) => {
      const subCats = categoriesData.subCategories[category.id] || [];

      return (
        <div className="absolute top-full left-0 bg-white shadow-lg rounded-lg w-72 py-2 z-50 category-menu-container">
          <Link
            to={`/collection?category=${category.id}`}
            className="block w-full text-left px-4 py-2 text-primary hover:bg-gray-100"
            onClick={() => setActiveCategory(null)}
          >
            All {category.name}
          </Link>
          {subCats.map((subCat) => {
            const hasLevel3 =
              (categoriesData.level3Categories[subCat.id] || []).length > 0;

            return (
              <div
                key={subCat.id}
                className="relative group category-menu-container"
              >
                <div
                  className="block w-full text-left px-4 py-2 text-gray-600 hover:bg-gray-100 flex justify-between items-center cursor-pointer"
                  onClick={(e) => handleSubCategoryClick(subCat.id, e)}
                >
                  <Link
                    to={`/collection?category=${subCat.id}`}
                    className="flex-grow"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveCategory(null);
                    }}
                  >
                    {subCat.name}
                  </Link>
                  {hasLevel3 && <ChevronRight className="h-4 w-4" />}
                </div>
                {activeSubCategory === subCat.id && hasLevel3 && (
                  <div className="absolute top-0 left-full bg-white shadow-lg rounded-lg w-72 py-2">
                    {(categoriesData.level3Categories[subCat.id] || []).map(
                      (level3) => (
                        <Link
                          key={level3.id}
                          to={`/collection?category=${level3.id}`}
                          className="block w-full text-left px-4 py-2 text-gray-600 hover:bg-gray-100"
                          onClick={() => {
                            setActiveCategory(null);
                            setActiveSubCategory(null);
                          }}
                        >
                          {level3.name}
                        </Link>
                      )
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    };
  }, [categoriesData, activeSubCategory]);

  const MobileCategoryMenu = useMemo(() => {
    return () => (
      <div className="space-y-4">
        {categoriesData.mainCategories.map((category) => (
          <div key={category.id} className="border-b pb-2">
            <div className="flex justify-between items-center">
              <Link
                to={`/collection?category=${category.id}`}
                onClick={() => setIsMobileMenuOpen(false)}
                className="text-gray-700 hover:text-primary font-medium"
              >
                {category.name}
              </Link>
              {(categoriesData.subCategories[category.id] || []).length > 0 && (
                <button
                  onClick={() => toggleExpanded(category.id)}
                  className="p-2 text-gray-500 hover:text-primary"
                >
                  <ChevronDown
                    className={`h-5 w-5 transition-transform duration-200 ${
                      expandedItems[category.id] ? "rotate-180" : ""
                    }`}
                  />
                </button>
              )}
            </div>

            {expandedItems[category.id] && (
              <div className="mt-2 ml-4 space-y-2">
                {(categoriesData.subCategories[category.id] || []).map(
                  (subCat) => (
                    <div key={subCat.id}>
                      <div className="flex justify-between items-center">
                        <Link
                          to={`/collection?category=${subCat.id}`}
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="text-gray-600 hover:text-primary"
                        >
                          {subCat.name}
                        </Link>
                        {(categoriesData.level3Categories[subCat.id] || [])
                          .length > 0 && (
                          <button
                            onClick={() => toggleExpanded(subCat.id)}
                            className="p-2 text-gray-500 hover:text-primary"
                          >
                            <ChevronDown
                              className={`h-4 w-4 transition-transform duration-200 ${
                                expandedItems[subCat.id] ? "rotate-180" : ""
                              }`}
                            />
                          </button>
                        )}
                      </div>

                      {expandedItems[subCat.id] && (
                        <div className="mt-2 ml-4 space-y-2">
                          {(
                            categoriesData.level3Categories[subCat.id] || []
                          ).map((level3) => (
                            <Link
                              key={level3.id}
                              to={`/collection?category=${level3.id}`}
                              onClick={() => setIsMobileMenuOpen(false)}
                              className="block text-gray-500 hover:text-primary"
                            >
                              {level3.name}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }, [categoriesData, expandedItems, toggleExpanded, setIsMobileMenuOpen]);

  if (loading) {
    return (
      <nav className="fixed top-0 left-0 right-0 z-50 bg-white shadow-lg">
        <div className="w-full px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0">
              <img src={logo} alt="Logo" className="h-8 w-auto md:h-12" />
            </div>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 bg-white
        ${isScrolled ? "shadow-lg" : ""}`}
    >
      <div className="w-full px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0">
            <Link to="/">
              <img src={logo} alt="Logo" className="h-8 w-auto md:h-12" />
            </Link>
          </div>
          <div className="flex items-center">
            <div className="hidden md:flex items-center space-x-6">
              {NAV_ITEMS.map((item) => {
                const itemPath =
                  item === "Home" ? "/" : `/${item.toLowerCase()}`;
                return (
                  <Link
                    key={item}
                    to={itemPath}
                    className={`px-1 py-2 transition-colors ${
                      location.pathname === itemPath
                        ? "text-primary"
                        : "text-gray-700 hover:text-primary"
                    }`}
                  >
                    {item}
                  </Link>
                );
              })}
            </div>
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="ml-4 p-2 text-gray-700 hover:text-primary focus:outline-none focus:ring md:hidden"
            >
              {isMobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Desktop Categories Row */}
      <div className="hidden md:block border-t">
        <div className="w-full">
          <div
            style={{ marginLeft: "20%" }}
            className="flex space-x-8 py-3 px-4"
          >
            {categoriesData.mainCategories.map((category) => (
              <div
                key={category.id}
                className="relative category-menu-container"
              >
                <button
                  onClick={(e) => handleCategoryClick(category.id, e)}
                  className={`flex items-center space-x-1 text-gray-700 hover:text-primary transition-colors ${
                    activeCategory === category.id ? "text-primary" : ""
                  }`}
                >
                  <span>{category.name}</span>
                  <ChevronDown
                    className={`h-4 w-4 transition-transform duration-200 ${
                      activeCategory === category.id ? "rotate-180" : ""
                    }`}
                  />
                </button>
                {activeCategory === category.id && (
                  <CategoryDropdown category={category} />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden fixed inset-0 z-40 bg-black bg-opacity-50">
          <div
            className={`fixed inset-y-0 right-0 w-80 bg-white transform transition-transform duration-300 ease-in-out ${
              isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="h-full flex flex-col">
              <div className="flex justify-end p-4">
                <button
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="p-2 text-gray-700 hover:text-primary focus:outline-none focus:ring"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>

              <div className="border-b overflow-x-auto">
                <div className="flex px-4 space-x-6 min-w-min py-4">
                  {NAV_ITEMS.map((item) => {
                    const itemPath =
                      item === "Home" ? "/" : `/${item.toLowerCase()}`;
                    return (
                      <Link
                        key={item}
                        to={itemPath}
                        onClick={() => setIsMobileMenuOpen(false)}
                        className={`whitespace-nowrap px-2 py-1 text-gray-700 hover:text-primary font-medium transition-colors
                          ${
                            location.pathname === itemPath
                              ? "text-primary border-b-2 border-primary"
                              : ""
                          }`}
                      >
                        {item}
                      </Link>
                    );
                  })}
                </div>
              </div>

              <div className="flex-1 overflow-y-auto">
                <div className="p-4">
                  <MobileCategoryMenu />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
