import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { db } from "../config/firebase";
import { HERO_IMAGES } from "../constants/images";
import Contact from "./Contact";

const FEATURES = [
  {
    icon: "🛠️",
    title: "Custom Design",
    description: "Customization available for all furniture",
  },
  {
    icon: "💫",
    title: "Quality Service",
    description: "Professional and courteous staff",
  },
  {
    icon: "🚚",
    title: "Quick Delivery",
    description: "Timely delivery and installation",
  },
  {
    icon: "💰",
    title: "Best Value",
    description: "Quality products at reasonable prices",
  },
];

const useIntersectionObserver = (options = {}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    const currentTarget = targetRef.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [options]);

  return [targetRef, isIntersecting];
};

export default function Home() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [collectionsRef, collectionsVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [productsRef, productsVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [featuresRef, featuresVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [ctaRef, ctaVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch categories
        const categoryQuery = query(
          collection(db, "categories"),
          where("level", "==", 1)
        );
        const categorySnapshot = await getDocs(categoryQuery);
        const categoryData = categorySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          imageUrl: doc.data().imageUrl,
          numericId: doc.data().numericId,
        }));

        // Sort categories by numericId
        const sortedCategories = categoryData.sort((a, b) => {
          const numA = parseInt(a.numericId) || 0;
          const numB = parseInt(b.numericId) || 0;
          return numA - numB;
        });

        setCategories(sortedCategories);

        // Fetch products (only 6 with specific fields)
        const productsQuery = query(collection(db, "products"), limit(6));
        const productsSnapshot = await getDocs(productsQuery);
        const productsData = productsSnapshot.docs.map((doc) => {
          const data = doc.data();
          const mainImage =
            data.images?.find((img) => img.isMain) || data.images?.[0];

          return {
            id: doc.id,
            name: data.name,
            originalPrice: data.originalPrice,
            discountPercentage: data.discountPercentage,
            discountedPrice: data.discountedPrice,
            categoryId: data.categoryId,
            description: data.description,
            image: mainImage
              ? {
                  url: mainImage.url,
                  id: mainImage.id,
                }
              : null,
          };
        });

        setProducts(productsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Slider timer
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % HERO_IMAGES.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const formatPrice = (price) => {
    if (typeof price !== "number") return "-";
    return `₹${price.toLocaleString("en-IN")}`;
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-gray-600">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 bg-primary text-white px-6 py-2 rounded-full"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative h-screen overflow-hidden">
        {HERO_IMAGES.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              currentSlide === index ? "opacity-100" : "opacity-0"
            }`}
          >
            <div
              className="h-full bg-cover bg-center relative"
              style={{ backgroundImage: `url(${image.url})` }}
            >
              <div className="absolute inset-0 bg-black/50" />
              <div className="absolute inset-0 flex items-center justify-center text-white text-center">
                <div className="max-w-4xl mx-auto px-4">
                  <h1 className="text-5xl md:text-7xl font-bold mb-6 animate-slide-up">
                    {image.title}
                  </h1>
                  <p className="text-xl md:text-2xl mb-8 animate-slide-up delay-200">
                    {image.subtitle}
                  </p>
                  <div className="animate-slide-up delay-400">
                    <Link
                      to="/collection"
                      className="bg-white text-primary px-8 py-3 rounded-full font-medium hover:bg-primary hover:text-white transition-all duration-300"
                    >
                      Explore Collection
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>

      {/* Collections Section */}
      <section ref={collectionsRef} className="py-24 bg-gray-50">
        <div className="container-padding">
          <div
            className={`text-center mb-16 transition-all duration-1000 transform ${
              collectionsVisible
                ? "translate-y-0 opacity-100"
                : "translate-y-10 opacity-0"
            }`}
          >
            <h2 className="text-4xl font-bold mb-4">Featured Collections</h2>
            <p className="text-gray-600 text-lg">
              Discover our handpicked selection
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {loading
              ? Array(3)
                  .fill(null)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-xl shadow-lg h-96 animate-pulse"
                    />
                  ))
              : categories.map((category, index) => (
                  <div
                    key={category.id}
                    className={`group relative overflow-hidden rounded-xl bg-white shadow-lg hover:-translate-y-2 hover:shadow-xl transition-all duration-1000 transform ${
                      collectionsVisible
                        ? "translate-y-0 opacity-100"
                        : "translate-y-10 opacity-0"
                    }`}
                    style={{ transitionDelay: `${index * 100}ms` }}
                  >
                    <div className="aspect-w-16 aspect-h-9 relative">
                      <img
                        src={category.imageUrl}
                        alt={category.name}
                        className="w-full h-64 object-cover transition-transform duration-500 group-hover:scale-110"
                      />
                      <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-40 transition-opacity duration-300" />
                    </div>

                    <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                      <h3 className="text-white text-2xl font-bold mb-2">
                        {category.name}
                      </h3>
                      <Link
                        to={`/collection?category=${category.id}`}
                        className="bg-white text-primary px-6 py-2 rounded-full font-medium hover:bg-primary hover:text-white transition-colors duration-300"
                      >
                        View Collection
                      </Link>
                    </div>

                    <div className="p-6 group-hover:opacity-0 transition-opacity duration-300">
                      <h3 className="text-xl font-semibold mb-2">
                        {category.name}
                      </h3>
                      <p className="text-gray-600 mb-2">
                        Explore our {category.name.toLowerCase()} collection
                      </p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </section>

      {/* Products Section */}
      <section ref={productsRef} className="py-24 bg-white">
        <div className="container-padding">
          <div
            className={`text-center mb-16 transition-all duration-1000 transform ${
              productsVisible
                ? "translate-y-0 opacity-100"
                : "translate-y-10 opacity-0"
            }`}
          >
            <h2 className="text-4xl font-bold mb-4">Featured Products</h2>
            <p className="text-gray-600 text-lg">
              Explore our most popular items
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {loading
              ? Array(6)
                  .fill(null)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-xl shadow-lg h-96 animate-pulse"
                    />
                  ))
              : products.map((product, index) => (
                  <div
                    key={product.id}
                    className={`bg-white rounded-xl shadow-lg overflow-hidden hover:-translate-y-2 hover:shadow-xl transition-all duration-1000 transform ${
                      productsVisible
                        ? "translate-y-0 opacity-100"
                        : "translate-y-10 opacity-0"
                    }`}
                    style={{ transitionDelay: `${index * 100}ms` }}
                  >
                    <div className="relative group">
                      {product.image && (
                        <img
                          src={product.image.url}
                          alt={product.name}
                          className="w-full h-64 object-cover transition-transform duration-500 group-hover:scale-110"
                        />
                      )}
                      <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-40 transition-opacity duration-300" />
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <Link
                          to={`/product/${product.id}`}
                          className="bg-white text-primary px-6 py-2 rounded-full font-medium hover:bg-primary hover:text-white transition-colors duration-300"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                    <div className="p-6">
                      <h3 className="text-xl font-semibold mb-2">
                        {product.name}
                      </h3>
                      <div className="space-y-2">
                        {typeof product.originalPrice === "number" && (
                          <div className="flex items-baseline gap-2">
                            <span className="text-gray-500">Original Price:</span>
                            <span
                              className={product.discountPercentage ? "text-black-500 line-through" : "text-black"}
                            >
                              {formatPrice(product.originalPrice)}
                            </span>
                          </div>
                        )}

                        {product.discountPercentage > 0 && (
                          <div className="flex items-baseline gap-2">
                            <span className="text-gray-500">Discount:</span>
                            <span className="text-green-600 font-semibold">
                              {product.discountPercentage}% OFF
                            </span>
                          </div>
                        )}

                        {typeof product.discountedPrice === "number" && (
                          <div className="flex items-baseline gap-2">
                            <span className="text-gray-500">Final Price:</span>
                            <span className="text-red-500 font-bold">
                              {formatPrice(product.discountedPrice)}
                            </span>
                          </div>
                        )}
                      </div>
                      {product.description && (
                        <p className="text-gray-600 mb-4 line-clamp-2 mt-2">
                          {product.description}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
          </div>

          <div
            className={`text-center mt-12 transition-all duration-1000 transform ${
              productsVisible
                ? "translate-y-0 opacity-100"
                : "translate-y-10 opacity-0"
            }`}
          >
            <Link
              to="/collection"
              className="inline-block bg-primary text-white px-8 py-3 rounded-full font-medium hover:bg-primary/90 transition-colors duration-300 hover:scale-105 transform"
            >
              View All Products
            </Link>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section ref={featuresRef} className="py-24 bg-gray-50">
        <div className="container-padding">
          <div
            className={`text-center mb-16 transition-all duration-1000 transform ${
              featuresVisible
                ? "translate-y-0 opacity-100"
                : "translate-y-10 opacity-0"
            }`}
          >
            <h2 className="text-4xl font-bold mb-4">Why Choose Us</h2>
            <p className="text-gray-600 text-lg">
              Experience the Malnad Furnitures difference
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {FEATURES.map((feature, index) => (
              <div
                key={feature.title}
                className={`bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-1000 transform hover:-translate-y-1 ${
                  featuresVisible
                    ? "translate-y-0 opacity-100"
                    : "translate-y-10 opacity-0"
                }`}
                style={{ transitionDelay: `${index * 100}ms` }}
              >
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section ref={ctaRef} className="py-24 bg-primary text-white">
        <div className="container-padding text-center">
          <div
            className={`transition-all duration-1000 transform ${
              ctaVisible
                ? "translate-y-0 opacity-100"
                : "translate-y-10 opacity-0"
            }`}
          >
            <h2 className="text-4xl font-bold mb-6">
              Ready to Transform Your Space?
            </h2>
            <p className="text-xl mb-8">
              Visit our showroom or get in touch with our experts today
            </p>
            <Link
              to="/contact"
              className="bg-white text-primary px-8 py-3 rounded-full font-medium hover:bg-gray-100 transition-all duration-300 hover:-translate-y-1 inline-block"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}