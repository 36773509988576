import { useEffect, useRef } from 'react';
import { AnimatedText } from '../components/AnimatedText';
import logo from '../images/logo.png';
import bg from '../images/About.png';

export default function About() {
  const timelineRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-fadeIn');
            entry.target.classList.add('translate-x-0');
            entry.target.classList.add('opacity-100');
          }
        });
      },
      { threshold: 0.1 }
    );

    const timelineItems = timelineRef.current?.querySelectorAll('.timeline-item');
    timelineItems?.forEach((item) => observer.observe(item));

    return () => {
      timelineItems?.forEach((item) => observer.unobserve(item));
    };
  }, []);

  const achievements = [
    { number: '5000+', text: 'Happy Customers' },
    { number: '500+', text: 'Furniture Designs' },
    { number: '20+', text: 'Years Experience' },
    { number: '100%', text: 'Quality Assurance' }
  ];

  const timeline = [
    {
      year: '2011',
      title: 'Foundation',
      description: 'Established our first showroom in Bangalore',
      icon: '🏪',
      color: 'bg-blue-500'
    },
    {
      year: '2015',
      title: 'Expansion',
      description: 'Opened our flagship store in Ramamurthy Nagar',
      icon: '🌟',
      color: 'bg-purple-500'
    },
    {
      year: '2018',
      title: 'Innovation',
      description: 'Introduced modern modular furniture solutions',
      icon: '💡',
      color: 'bg-green-500'
    },
    {
      year: '2024',
      title: 'Digital Transformation',
      description: 'Launched our online store and virtual showroom',
      icon: '🌐',
      color: 'bg-orange-500'
    }
  ];

  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section 
        className="relative h-[70vh] bg-fixed bg-cover bg-center"
        style={{
          backgroundImage: `url(${bg})`
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/50" />
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <AnimatedText className="text-6xl font-bold text-white text-center mb-6">
            Our Journey of Excellence
          </AnimatedText>
          <div className="w-24 h-1 bg-white rounded-full mb-6" />
          <p className="text-xl text-white/90 text-center max-w-2xl mx-4">
            Crafting exceptional furniture pieces since 2011, combining traditional craftsmanship with modern design.
          </p>
        </div>
      </section>

      {/* Achievement Stats */}
      <section className="py-16 bg-gradient-to-r from-primary to-secondary">
        <div className="container-padding">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {achievements.map((achievement, index) => (
              <div 
                key={index}
                className="text-center text-white p-6 rounded-lg backdrop-blur-sm bg-white/10 transform hover:scale-105 transition-all duration-300"
              >
                <h3 className="text-4xl font-bold mb-2">{achievement.number}</h3>
                <p className="text-lg opacity-90">{achievement.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="py-24 bg-white">
        <div className="container-padding">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <img src={logo} alt="Logo" className="h-16 w-auto mx-auto mb-6" />
            <p className="text-xl text-gray-600 leading-relaxed animate-fadeIn">
              At our furniture company, we believe in creating more than just furniture - we craft living spaces that inspire. 
              Our commitment to quality and customer satisfaction has made us one of Bangalore's most trusted furniture retailers, 
              delivering excellence in every piece we create.
            </p>
          </div>
        </div>
      </section>

      {/* Timeline Section */}
      <section className="py-24 bg-gray-50">
        <div className="container-padding">
          <h2 className="text-4xl font-bold text-center mb-16 text-gray-800">Our Journey</h2>
          <div ref={timelineRef} className="relative">
            <div className="absolute left-1/2 -ml-0.5 w-0.5 h-full bg-gray-200" />
            {timeline.map((item, index) => (
              <div
                key={item.year}
                className={`timeline-item relative mb-16 opacity-0 transition-all duration-700 ease-out ${
                  index % 2 === 0 ? 'md:pr-1/2 -translate-x-16' : 'md:pl-1/2 md:ml-auto translate-x-16'
                }`}
              >
                <div className={`md:w-1/2 ${index % 2 === 0 ? 'md:text-right' : ''}`}>
                  <div className={`relative bg-white p-8 rounded-xl shadow-lg transform hover:-translate-y-2 transition-all duration-300 ${
                    index % 2 === 0 ? 'mr-8' : 'ml-8'
                  }`}>
                    <div className={`absolute ${
                      index % 2 === 0 ? 'right-0 translate-x-1/2' : 'left-0 -translate-x-1/2'
                    } top-1/2 -translate-y-1/2 w-16 h-16 rounded-full ${item.color} text-white flex items-center justify-center text-2xl shadow-lg`}>
                      {item.icon}
                    </div>
                    <span className="inline-block text-sm font-semibold text-white px-4 py-2 rounded-full mb-4 bg-primary">
                      {item.year}
                    </span>
                    <h3 className="text-2xl font-bold mb-3 text-gray-800">{item.title}</h3>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-primary text-white">
        <div className="container-padding text-center">
          <h2 className="text-4xl font-bold mb-6">Ready to Transform Your Space?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto opacity-90">
            Visit our showroom or schedule a consultation with our furniture experts today.
          </p>
          <button className="px-8 py-3 bg-white text-primary rounded-full font-semibold hover:bg-gray-100 transform hover:scale-105 transition-all duration-300">
            Contact Us
          </button>
        </div>
      </section>
    </div>
  );
}