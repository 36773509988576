import one from './1.jpg';
import two from './2.jpg';
import three from './3.jpg';

// src/constants/images.js
export const HERO_IMAGES = [
  {
    url: one,
    title: "Modern Living",
    subtitle: "Transform your space with elegance"
  },
  {
    url: two,
    title: "Comfort & Style",
    subtitle: "Crafted for your lifestyle"
  },
  {
    url: three,
    title: "Premium Quality",
    subtitle: "Built to last generations"
  }
];

export const DUMMY_PRODUCTS = [
  {
    id: 1,
    name: "Modern Leather Sofa",
    description: "Elegant 3-seater leather sofa with chrome legs",
    price: 89999,
    image: "https://images.unsplash.com/photo-1550581190-9c1c48d21d6c",
    category: "Living Room"
  },
  {
    id: 2,
    name: "Ergonomic Office Chair",
    description: "Premium mesh office chair with lumbar support",
    price: 24999,
    image: "https://images.unsplash.com/photo-1580480055273-228ff5388ef8",
    category: "Office"
  },
  {
    id: 3,
    name: "King Size Bed Frame",
    description: "Solid wood bed frame with modern design",
    price: 64999,
    image: "https://images.unsplash.com/photo-1505693416388-ac5ce068fe85",
    category: "Bedroom"
  }
];


  // Add to constants/images.js
export const CATEGORIES = [
    { id: 'all', name: 'All' },
    { id: 'living-room', name: 'Living Room' },
    { id: 'bedroom', name: 'Bedroom' },
    { id: 'dining', name: 'Dining' },
    { id: 'office', name: 'Office' },
    { id: 'outdoor', name: 'Outdoor' }
  ];