import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../config/firebase";
import { AnimatedText } from "../components/AnimatedText";
import { ArrowLeft, Loader2, Home } from "lucide-react";
import { Link } from "react-router-dom";
import { ProductGrid } from "./ProductGrid";
import banner from "../images/bg.png";

// Cache for storing category data
const categoryCache = new Map();
const subcategoryCache = new Map();

export default function Collection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [switchingCategory, setSwitchingCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedCategoryData, setSelectedCategoryData] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const parallaxRef = useRef(null);

  // Parallax effect
  useEffect(() => {
    if (
      !selectedCategoryData ||
      selectedCategoryData?.level === 1 ||
      selectedCategory === "all"
    ) {
      const handleScroll = () => {
        if (parallaxRef.current) {
          const scrolled = window.scrollY;
          const rate = scrolled * 0.5;
          parallaxRef.current.style.transform = `translate3d(0, ${rate}px, 0)`;
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [selectedCategoryData, selectedCategory]);

  // URL params effect
  useEffect(() => {
    const categoryFromUrl = searchParams.get("category");
    if (categoryFromUrl) {
      setSelectedCategory(categoryFromUrl);
    }
  }, [searchParams]);

  // Categories fetch effect with caching
  useEffect(() => {
    const fetchCategories = async () => {
      setSwitchingCategory(true);
      
      try {
        // Check cache first for main categories
        if (categoryCache.has('main')) {
          setCategories(categoryCache.get('main'));
        } else {
          const mainCategoriesQuery = query(
            collection(db, "categories"),
            where("level", "==", 1)
          );
          const mainCategoriesSnapshot = await getDocs(mainCategoriesQuery);
          const mainCategories = mainCategoriesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          categoryCache.set('main', mainCategories);
          setCategories(mainCategories);
        }

        if (selectedCategory !== "all") {
          // Check cache for subcategories
          const subcategoryCacheKey = `sub_${selectedCategory}`;
          if (subcategoryCache.has(subcategoryCacheKey)) {
            const cached = subcategoryCache.get(subcategoryCacheKey);
            setSubcategories(cached.subcategories);
            setSelectedCategoryData(cached.categoryData);
            setBreadcrumb(cached.breadcrumb);
          } else {
            const [categoryData, subcategoryData, breadcrumbData] = await Promise.all([
              fetchCategoryData(selectedCategory),
              fetchSubcategories(selectedCategory),
              buildBreadcrumb(selectedCategory)
            ]);

            setSelectedCategoryData(categoryData);
            setSubcategories(subcategoryData);
            setBreadcrumb(breadcrumbData);

            // Cache the results
            subcategoryCache.set(subcategoryCacheKey, {
              subcategories: subcategoryData,
              categoryData,
              breadcrumb: breadcrumbData
            });
          }
        } else {
          setSelectedCategoryData(null);
          setSubcategories([]);
          setBreadcrumb([]);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setSwitchingCategory(false);
        setInitialLoading(false);
      }
    };

    fetchCategories();
  }, [selectedCategory]);

  // Helper functions for category data fetching
  const fetchCategoryData = async (categoryId) => {
    const categoryQuery = query(
      collection(db, "categories"),
      where("__name__", "==", categoryId)
    );
    const snapshot = await getDocs(categoryQuery);
    return snapshot.empty ? null : snapshot.docs[0].data();
  };

  const fetchSubcategories = async (categoryId) => {
    const subcategoriesQuery = query(
      collection(db, "categories"),
      where("parentId", "==", categoryId)
    );
    const snapshot = await getDocs(subcategoriesQuery);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const buildBreadcrumb = async (categoryId) => {
    const categoryData = await fetchCategoryData(categoryId);
    if (!categoryData) return [];

    if (categoryData.level === 1) {
      return [{ id: categoryId, name: categoryData.name }];
    }

    const parentData = await fetchCategoryData(categoryData.parentId);
    return [
      { id: categoryData.parentId, name: parentData.name },
      { id: categoryId, name: categoryData.name }
    ];
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
    setSearchParams({ category: categoryId });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    if (breadcrumb.length > 1) {
      handleCategorySelect(breadcrumb[0].id);
    } else {
      setSelectedCategory("all");
      setSearchParams({});
    }
  };

  const renderHeader = () => {
    const showParallax =
      !selectedCategoryData ||
      selectedCategoryData?.level === 1 ||
      selectedCategory === "all";

    if (showParallax) {
      return (
        <div style={{ marginTop: "25px" }} className="parallax-container">
          <div ref={parallaxRef} className="parallax-image">
            <img
              src={banner}
              alt="Collection Banner"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="parallax-content">
            <div className="container-padding h-full">
              {renderTopNavigation()}
              <div className="flex items-center justify-center h-full">
                <AnimatedText className="text-4xl font-bold text-center text-white">
                  {selectedCategoryData
                    ? selectedCategoryData.name
                    : "All Collections"}
                </AnimatedText>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="bg-gray-100 py-8">
          <div className="container-padding">
            {renderTopNavigation()}
            <div className="mt-4">
              <h1 className="text-3xl font-bold text-gray-900">
                {selectedCategoryData?.name}
              </h1>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderTopNavigation = () => (
    <div className="mb-8 flex items-center justify-between pt-4">
      <div className="flex items-center gap-4">
        <Link
          to="/"
          className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm hover:bg-gray-50 transition-colors"
        >
          <Home className="h-5 w-5" />
          <span>Home</span>
        </Link>

        {selectedCategory !== "all" && (
          <button
            onClick={handleBack}
            className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm hover:bg-gray-50 transition-colors"
          >
            <ArrowLeft className="h-5 w-5" />
            <span>Back</span>
          </button>
        )}
      </div>

      {breadcrumb.length > 0 && (
        <div className="flex items-center gap-2 text-white">
          {breadcrumb.map((item, index) => (
            <div key={item.id} className="flex items-center gap-2">
              <span
                className={
                  index === breadcrumb.length - 1
                    ? "text-primary-light"
                    : "text-white"
                }
              >
                {item.name}
              </span>
              {index < breadcrumb.length - 1 && (
                <span className="text-gray-300">/</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const styles = `
    .parallax-container {
      position: relative;
      height: 64vh;
      overflow: hidden;
    }
    .parallax-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 120%;
      object-fit: cover;
      will-change: transform;
    }
    .parallax-content {
      position: relative;
      height: 100%;
      z-index: 1;
      background: rgba(0, 0, 0, 0.4);
    }
  `;

  if (initialLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-12 w-12 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <div className="pt-20">
      <style>{styles}</style>
      {renderHeader()}
      <div className="container-padding bg-gray-50 py-16">
        {switchingCategory ? (
          <div className="flex items-center justify-center py-20">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        ) : (
          <ProductGrid
            selectedCategory={selectedCategory}
            categories={categories}
            subcategories={subcategories}
            handleCategorySelect={handleCategorySelect}
            handleBack={handleBack}
          />
        )}
      </div>
    </div>
  );
}