// src/components/AnimatedText.jsx
export const AnimatedText = ({ children, className = '' }) => {
  const words = children.split(' ');
  
  return (
    <div className={`overflow-hidden ${className}`}>
      {words.map((word, idx) => (
        <span
          key={idx}
          className="inline-block mr-2 animate-slideUp"
          style={{ 
            animationDelay: `${idx * 0.1}s`,
            opacity: 0,
            animation: 'slideUp 0.5s ease forwards'
          }}
        >
          {word}
        </span>
      ))}
    </div>
  );
};