import logo from '../images/logo.png';

export default function Footer() {
  return (
    <footer className="bg-primary text-white py-8">
      <div className="container-padding">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <img src={logo} alt="Logo" className="h-12 w-auto mb-4" />
            <p className="text-sm">
              Quality furniture since 2011
            </p>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Contact</h3>
            <p className="text-sm">
              No. 29 and 30, Tc Palya Main Road<br />
              Hoysala Nagar, Ramamurthy Nagar<br />
              Bangalore - 560016<br />
              Phone: <a href="tel:+919844054322" className="underline">+91 98440 54322</a>
            </p>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Hours</h3>
            <p className="text-sm">
              Monday - Sunday<br />
              10:00 AM - 9:00 PM
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
