import { useState, useEffect, useCallback } from 'react';

const TESTIMONIALS = [
    {
      name: "Callistus Dgama",
      review: "My association with Malnad furnitures goes back to 2021; when I purchased a 3+1+1 Sofa with individual cushions for seats and backrest. With proper washing of the cushion covers, the sofa appeared new until 2024. Only 2 of the cushion covers needed replacement after 2 years.",
      rating: 5,
      highlighted: true
    },
    {
      name: "Gaurav Lakhera",
      review: "Overall nice experience from the day I booked king size Assam wood bed till delivery. Owner is very polite and gives time to customers to understand their exact requirements. I had changed my plan twice and they were able to get it done based on my new requirement. Highly recommended shop for furnitures.",
      rating: 5,
      highlighted: true
    },
    {
      name: "Naveen Kumar",
      review: "I am thoroughly impressed with Malnad Furniture for their exceptional affordability, punctual delivery, and outstanding customer service. Their team is remarkably adept at establishing rapport with customers, demonstrating a delightful level of professionalism.",
      rating: 5,
      highlighted: true
    },
    {
      name: "madhu sudhan",
      review: "Have been ordering furniture from this store for last 2 years now. Placed order for these items - dining table, sofa set, queen sized cot. Quality of products prepared is top notch at reasonable prices. Very satisfied with the service.",
      rating: 5,
      highlighted: true
    },
    {
      name: "Udbhav Sinha",
      review: "Bought 2 cots from Malnad Furnitures and I must say, quality is just awesome for the price point. Delivery was super fast too. Definitely recommend to buy cots and mattresses from the shop!!",
      rating: 5,
      highlighted: true
    },
    {
      name: "ZuHA",
      review: "I recently purchased a bed and bookshelf from Malnad Furniture, and I'm thoroughly impressed! The quality and design of both pieces exceeded my expectations. The bed is incredibly comfortable, and the bookshelf is sturdy and well-built.",
      rating: 5,
      highlighted: true
    },
    {
      name: "Arun Kumar Goud",
      review: "Malnad furnitures, really really I am grateful for the product you delivered us. Whatever I have asked for, the customisation and all, everything is so perfectly done with standard quality. I would definitely recommend others.",
      rating: 5,
      highlighted: true
    },
    {
      name: "Ashfaq Ahmed Ameer",
      review: "Just wanted to drop a note to say how impressed I was with the service you and your team had provided. It's highly appreciated. I have checked the market and found that the price what I have paid is worth and the material what I have requested was much better.",
      rating: 5
    },
    {
      name: "Manojkumar Reddy",
      review: "You will get best Service and super quality... More over they will give atmost priority to their customers concerns and they will customize according to customer choice...",
      rating: 5
    },
    {
      name: "Mohammed Asif",
      review: "I bought a queen bed from Malnad Furnitures and could not control myself from sharing this review. The quality was as per my expectations and the delivery was done as per the committed time. The price was also much affordable.",
      rating: 5
    },
    {
      name: "Tanuj Jain",
      review: "It is one of the best furniture shops in Kalyannagar. Prices are very reasonable, owner and all the staffs associated with the shop are very helpful and understanding.",
      rating: 5
    },
    {
      name: "Pavan Chellipalli",
      review: "The team delivered furniture(sofa&tea poi) was the right fit for our home, excellent quality and response from salesmen is too good.",
      rating: 5
    },
    {
      name: "Fritze George",
      review: "Fast and committed delivery. Lucrative prices and sturdy furniture. Worth every penny.",
      rating: 5
    },
    {
      name: "pruthvi r",
      review: "I had placed a custom order for a diwan based on the photo i found on internet. Diwan was delivered as promised with nice finishing. Mr.Salman the owner, was very helpful during the entire process.",
      rating: 5
    },
    {
      name: "Sathish Kumar",
      review: "Excellent service.. understood requirements and fulfilled as per our need. Very flexible with delivery.",
      rating: 5
    },
    {
      name: "momin Waqar",
      review: "Nice quality furniture at affordable price and the owner explains very well and helps through out the process.. Must visit",
      rating: 5
    },
    {
      name: "Sai Chittaranjan",
      review: "Salman bhai ensures great quality at adorable prices. He is ready to customise and always provide on time delivery of ordered furniture. We have ordered two sets of furniture from them at two different times and we are very happy with the quality of products.",
      rating: 5,
      highlighted: true
    },
    {
      name: "Yokesh Prakasam",
      review: "My brother and I had recently purchased wfh essentials from Malnad Furnitures and I would stress the fact that there will not be any regrets. We are completely satisfied with the level of products they have and the owner and boys are very good at their work.",
      rating: 5
    },
    {
      name: "Aritra Banerjee",
      review: "I have bought a dinning table set (wooden). Sales persons are well mannered. The Shop has installment facilities. Varieties of furniture available.",
      rating: 5
    },
    {
      name: "Kushaal Gowda",
      review: "We got 2 Couches, 2 Dividers, 1 Teapoy and 8 Dining Chairs done. Very good quality. Fast delivery and reasonably priced. I highly recommend this place for getting your furnitures customised.",
      rating: 5,
      highlighted: true
    },
    {
      name: "Shujath Pasha",
      review: "It was an excellent experience dealing with Malnad Furnitures where I got a detailed explanation and consultation before I could buy my product lately. The product I received was very good and on time delivery.",
      rating: 5
    },
    {
      name: "Sobi S",
      review: "The quality is very good especially Shakthi brand is what I liked the most. Delivery is done ontime and the installation as well. I strongly recommend Malnad furnitures.",
      rating: 5
    },
    {
      name: "Vinayak Bagalkoti",
      review: "I purchased a 5 step ramp in malnad furnitures for dasara festival. I got the product on time and quality is very good. Before I have purchased cot, teak wood sofa set. Quality of products are very good.",
      rating: 5
    },
    {
      name: "Radha Vemuru",
      review: "They asked me to visit the shop I denied. But really when they brought item - shoe rack ultimately product Awesome, fantastic I amazed. Hundred percent you can trust.",
      rating: 5
    },
    {
      name: "Kaarthick Gajapathy",
      review: "We got few sofas purchased from this shop for our families and friends and happy to see the quality of work and worth for money",
      rating: 5
    },
    {
      name: "Nanjunda KC",
      review: "I have bought all my house needs. Sales persons are well mannered. The Shop has installment facilities. Varieties of furniture available.",
      rating: 5
    },
    {
      name: "Ashok Kumar Sarma R",
      review: "I bought an office table. It was a custom made one and they were able to satisfy my requirements. Very good service and they delivered the product on time.",
      rating: 5
    },
    {
      name: "Arpit Malhotra",
      review: "Extremely professional service. Delivery was done one day before than the actual promised delivery date. One of the only shops in Bengaluru where utmost attention was given to customer's requirement.",
      rating: 5
    },
    {
      name: "Shashank Srinivasan",
      review: "Recently we bought queen size mattress, side wall stand and teapoi. Best quality and satisfied for these products which we have bought from malnad furniture worth buying from this place with affordable price.",
      rating: 5
    },
    {
      name: "Shoeb Khan",
      review: "Properter Mr.Ansar is well qualified and experienced in manufacturing the Furniture which are suites to our taste and size to my house and moreover very Friendly.",
      rating: 5
    },
    {
      name: "Ravi Shankar Dokka",
      review: "Very good quality furniture with complete satisfaction of whatever you purchase.",
      rating: 5
    },
    {
      name: "Prabhakar Lingutla",
      review: "Malnad furnitures makes good quality of various house hold furniture. I personally purchased Study table, Cot, Kitchen cabinet. All are made up with good quality and nice finishing.",
      rating: 5
    },
    {
      name: "debasish sinha",
      review: "Great customer service and product quality. Definitely a great place to shop for furniture. Owner is really helpful and will provide you in an affordable price.",
      rating: 5
    },
    {
      name: "mahesha m s",
      review: "Good Product quality, on time neat delivery and flexibility.",
      rating: 5
    },
    {
      name: "mohammed mujahid",
      review: "Excellent place for all your furniture needs, be it for your home or office, they customize furniture according to your liking, quality wood was used in all my orders.",
      rating: 5
    },
    {
      name: "Tafazzul Najeeb",
      review: "Purchased a customized shoe rack. Quality and finishing of the product is very good. Price is also affordable with many choices of colours.",
      rating: 5
    },
    {
      name: "ameenul hasan",
      review: "Very pleasant experience dealing with Malnad furnitures. You can trust for quality and price. I am a satisfied customer. Delivery was on time.",
      rating: 5
    },
    {
      name: "Raji Kumar",
      review: "Excellent product and great customer service experience with Malnad furnitures. This is second time I took from this shop and product was delivered within specified time.",
      rating: 5
    },
    {
      name: "Mohammed Sharukh siddiqui",
      review: "Very good place to purchase genuine furniture at reasonable prices. Staff is very cooperative and friendly. I purchased a chair from here and I am extremely satisfied with the quality and pricing.",
      rating: 5
    },
    {
      name: "Nitin Thakur",
      review: "Bought a sofa and bed, the quality is great and they were able to deliver the same on time.",
      rating: 5
    },
    {
      name: "Ramesha Rangappa",
      review: "Highly recommended- good quality of products",
      rating: 5
    },
    {
      name: "Syed Sagheer",
      review: "Amazing range of all kinds of furniture at very decent prices. The material used is also v good. Their tables and chairs as well as sofas and Beds are really good",
      rating: 5
    },
    {
      name: "47un",
      review: "Good quality furniture with the option of custom designs. Knowledgeable and helpful staff. Swift and timely delivery. Highly recommended!",
      rating: 5
    },
    {
      name: "Abhijith Kandankutty",
      review: "Furnitures are delivered on time, they are sturdy and perfect fit for long term use. They convert your idea into an aesthetic material.",
      rating: 5
    },
    {
      name: "Sandeep Kumar",
      review: "Thanks to you so much sir, very beautiful product you delivered to me on time. Thank you so much for your design and color selection for sofa. Highly recommended to needies....",
      rating: 5
    },
    {
      name: "Balaguru S",
      review: "Very good place for quality furnitures at reasonable price. You can get it customized, share your own design or select from available choices.",
      rating: 5
    },
    {
      name: "Saddiq Pasha",
      review: "Good quality and good service",
      rating: 5
    },
    {
      name: "Annapoorani Ravi",
      review: "Year before we bought table and chair from here, both are amazing and cost is worth the quality. Affordable ranges available with good quality.",
      rating: 5
    },
    {
      name: "Sagnik Ganguly",
      review: "Great Experience. Good price. Awesome Quality. Well-mannered Staff. 100% genuine. Totally recommended",
      rating: 5
    },
    {
      name: "Abirami Divakar",
      review: "Sofa quality is too good...Service and customer handling is very good.",
      rating: 5
    },
    {
      name: "suman saurabh",
      review: "Good place to buy furniture in Bangalore with reasonable price and timely delivery.",
      rating: 5
    },
    {
      name: "Soumya Elizabeth Abraham",
      review: "We bought 3 cots for a reasonable price. Loved it",
      rating: 5
    }
  ];

export default function Testimonials() {
  const [featuredIndex, setFeaturedIndex] = useState(0);
  const [filter, setFilter] = useState('all'); // 'all' or 'featured'

  // Auto-scroll featured testimonials
  useEffect(() => {
    const timer = setInterval(() => {
      setFeaturedIndex(prev => 
        (prev + 1) % TESTIMONIALS.filter(t => t.highlighted).length
      );
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  // Filter testimonials based on current filter
  const filteredTestimonials = TESTIMONIALS.filter(t => 
    filter === 'all' || (filter === 'featured' && t.highlighted)
  );

  const renderStars = useCallback((rating) => {
    return Array(rating).fill('★').map((star, i) => (
      <span key={i} className="text-yellow-400">
        {star}
      </span>
    ));
  }, []);

  return (
    <section className="py-24 bg-gradient-to-b from-gray-50 to-white">
      <div className="container-padding">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold mb-6 bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
            Customer Stories
          </h2>
          <p className="text-gray-600 text-xl max-w-2xl mx-auto">
            Discover why our customers love Malnad Furnitures through their experiences
          </p>
        </div>

        {/* Featured Testimonial */}
        <div className="max-w-4xl mx-auto mb-16">
          <div className="relative">
            <div className="absolute inset-0 bg-primary opacity-5 rounded-3xl transform rotate-3"></div>
            <div className="relative bg-white p-8 md:p-12 rounded-2xl shadow-xl">
              <div className="flex items-center justify-between mb-8">
                <div>
                  <h3 className="text-2xl font-bold text-primary mb-2">
                    {TESTIMONIALS[featuredIndex].name}
                  </h3>
                  <div className="text-xl">
                    {renderStars(TESTIMONIALS[featuredIndex].rating)}
                  </div>
                </div>
                <div className="text-6xl text-primary opacity-20 font-serif">❝</div>
              </div>
              <p className="text-xl text-gray-700 italic leading-relaxed">
                "{TESTIMONIALS[featuredIndex].review}"
              </p>
            </div>
          </div>
        </div>

        {/* Filter Buttons */}
        <div className="flex justify-center gap-4 mb-12">
          <button
            onClick={() => setFilter('all')}
            className={`px-6 py-2 rounded-full transition-all duration-300 ${
              filter === 'all'
                ? 'bg-primary text-white shadow-lg'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            All Reviews
          </button>
          <button
            onClick={() => setFilter('featured')}
            className={`px-6 py-2 rounded-full transition-all duration-300 ${
              filter === 'featured'
                ? 'bg-primary text-white shadow-lg'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            Featured Reviews
          </button>
        </div>

        {/* Grid of Testimonials */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredTestimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 ${
                testimonial.highlighted ? 'border-2 border-primary/10' : ''
              }`}
            >
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="font-semibold text-lg mb-1">{testimonial.name}</h3>
                    <div className="text-lg">
                      {renderStars(testimonial.rating)}
                    </div>
                  </div>
                  {testimonial.highlighted && (
                    <div className="bg-primary/10 text-primary text-xs px-3 py-1 rounded-full">
                      Featured
                    </div>
                  )}
                </div>
                <div className="relative">
                  <p className="text-gray-600 leading-relaxed">
                    "{testimonial.review}"
                  </p>
                  <div className="absolute -top-4 -left-2 text-4xl text-primary opacity-10">❝</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Call to Action */}
        <div className="text-center mt-16">
          <p className="text-xl text-gray-600 mb-6">
            Join our satisfied customers and experience the Malnad Furnitures difference
          </p>
          <a 
            href="/contact" 
            className="inline-block bg-primary text-white px-8 py-3 rounded-full hover:bg-primary/90 transition-colors duration-300 shadow-lg hover:shadow-xl"
          >
            Visit Our Showroom
          </a>
        </div>
      </div>
    </section>
  );
}