import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { db } from "../config/firebase";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { ArrowLeft, ChevronLeft, ChevronRight, X } from "lucide-react";

// Simple cache for product data
const productCache = new Map();

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigationLock = useRef(false);

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [siblingProducts, setSiblingProducts] = useState({
    prev: null,
    next: null,
  });

  // Hardcoded key features
  const KEY_FEATURES = [
    "Premium Quality Materials",
    "Professional Craftsmanship",
    "Customization Available",
    "Installation Service Included",
    "Quality Assurance",
    "Expert Support",
  ];

  // Function to format price
  const formatPrice = useCallback((price) => {
    if (typeof price !== "number") return "-";
    return `₹${price.toLocaleString("en-IN")}`;
  }, []);

  // Function to fetch a product with caching
  const fetchProduct = useCallback(async (productId) => {
    if (productCache.has(productId)) {
      return productCache.get(productId);
    }

    const productDoc = await getDoc(doc(db, "products", productId));
    if (!productDoc.exists()) return null;

    const productData = {
      id: productDoc.id,
      ...productDoc.data(),
      timestamp: Date.now(),
    };

    if (productCache.size >= 50) {
      const firstKey = productCache.keys().next().value;
      productCache.delete(firstKey);
    }
    productCache.set(productId, productData);
    return productData;
  }, []);

  // Optimized function to fetch sibling products
  const fetchSiblingProducts = useCallback(
    async (categoryId, currentSequenceId) => {
      try {
        const productsRef = collection(db, "products");

        // Single query to get all products in sequence
        const productsQuery = query(
          productsRef,
          where("categoryId", "==", categoryId),
          orderBy("sequenceId", "asc")
        );

        const snapshot = await getDocs(productsQuery);

        // Find current product index
        const productIndex = snapshot.docs.findIndex(
          (doc) => doc.data().sequenceId === currentSequenceId
        );

        return {
          prev:
            productIndex > 0
              ? { id: snapshot.docs[productIndex - 1].id }
              : null,
          next:
            productIndex < snapshot.docs.length - 1
              ? { id: snapshot.docs[productIndex + 1].id }
              : null,
        };
      } catch (error) {
        console.error("Error fetching sibling products:", error);
        return { prev: null, next: null };
      }
    },
    []
  );

  // Function to handle navigation
  const handleNavigation = useCallback(
    async (direction) => {
      if (navigationLock.current) return;
      navigationLock.current = true;

      try {
        const siblings = await fetchSiblingProducts(
          product.categoryId,
          product.sequenceId
        );
        const targetProduct =
          direction === "prev" ? siblings.prev : siblings.next;

        if (targetProduct) {
          navigate(`/product/${targetProduct.id}`);
        }
      } catch (error) {
        console.error("Navigation error:", error);
      } finally {
        // Only unlock after a short delay to prevent rapid keypresses
        setTimeout(() => {
          navigationLock.current = false;
        }, 300);
      }
    },
    [navigate, fetchSiblingProducts, product]
  );

  const handlePrevImage = useCallback(
    (e) => {
      e?.stopPropagation();
      setSelectedImageIndex((prev) =>
        prev === 0 ? product?.images?.length - 1 : prev - 1
      );
    },
    [product?.images?.length]
  );

  const handleNextImage = useCallback(
    (e) => {
      e?.stopPropagation();
      setSelectedImageIndex((prev) =>
        prev === product?.images?.length - 1 ? 0 : prev + 1
      );
    },
    [product?.images?.length]
  );

  // Reset scroll position when product changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  // Main product fetch effect
  useEffect(() => {
    const loadProduct = async () => {
      try {
        setLoading(true);
        setSelectedImageIndex(0);
        const productData = await fetchProduct(id);

        if (!productData) {
          setLoading(false);
          return;
        }

        setProduct(productData);

        if (productData.categoryId && productData.sequenceId !== undefined) {
          const siblings = await fetchSiblingProducts(
            productData.categoryId,
            productData.sequenceId
          );
          setSiblingProducts(siblings);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadProduct();
    }
  }, [id, fetchProduct, fetchSiblingProducts]);

  // Enhanced keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (isModalOpen) {
        // Image modal navigation
        if (e.key === "ArrowLeft") handlePrevImage();
        else if (e.key === "ArrowRight") handleNextImage();
        else if (e.key === "Escape") setIsModalOpen(false);
        return;
      }

      // Product navigation
      if (e.key === "ArrowLeft") handleNavigation("prev");
      else if (e.key === "ArrowRight") handleNavigation("next");
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [isModalOpen, handleNavigation, handlePrevImage, handleNextImage]);

  // Cache cleanup effect
  useEffect(() => {
    const cleanupInterval = setInterval(() => {
      const now = Date.now();
      [...productCache.entries()].forEach(([key, value]) => {
        if (now - value.timestamp > 5 * 60 * 1000) {
          productCache.delete(key);
        }
      });
    }, 60000);

    return () => clearInterval(cleanupInterval);
  }, []);

  // Modal component for fullscreen images
  const ImageModal = useCallback(() => {
    if (!product?.images?.[selectedImageIndex]?.url) return null;

    return (
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90 p-4"
        onClick={() => setIsModalOpen(false)}
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(false);
          }}
          className="absolute top-4 right-4 text-white hover:text-gray-300 p-2"
        >
          <X className="h-8 w-8" />
        </button>

        <img
          src={product.images[selectedImageIndex].url}
          alt={product.name}
          className="max-w-[90vw] max-h-[90vh] object-contain"
          onClick={(e) => e.stopPropagation()}
        />

        {product.images.length > 1 && (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handlePrevImage(e);
              }}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white text-gray-800 p-2 rounded-full shadow-lg transition-all"
            >
              <ChevronLeft className="h-6 w-6" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleNextImage(e);
              }}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white text-gray-800 p-2 rounded-full shadow-lg transition-all"
            >
              <ChevronRight className="h-6 w-6" />
            </button>
          </>
        )}
      </div>
    );
  }, [product, selectedImageIndex, handlePrevImage, handleNextImage]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="flex flex-col items-center justify-center h-screen w-full">
        <h2 className="text-2xl font-semibold mb-4">Product not found</h2>
        <Link
          to="/collection"
          className="text-primary hover:underline flex items-center gap-2"
        >
          <ArrowLeft className="h-4 w-4" />
          Back to Collection
        </Link>
      </div>
    );
  }

  const sortedDescription = Array.isArray(product.description)
    ? [...product.description].sort((a, b) => (a.order || 0) - (b.order || 0))
    : [];

  return (
    <>
      {isModalOpen && <ImageModal />}
      <div className="min-h-screen w-full bg-gray-50 pt-24">
        <div className="w-full h-full overflow-y-auto">
          <div className="container mx-auto px-4 py-8">
            {/* Navigation Header */}
            <div className="flex justify-between items-center mb-8">
              <Link
                to="/collection"
                className="inline-flex items-center text-gray-600 hover:text-primary transition-colors"
              >
                <ArrowLeft className="h-4 w-4 mr-2" />
                Back to Collection
              </Link>

              <div className="flex items-center gap-4">
                {siblingProducts.prev && (
                  <button
                    onClick={() => handleNavigation("prev")}
                    className="text-gray-600 hover:text-primary transition-colors flex items-center gap-1"
                  >
                    <ChevronLeft className="h-4 w-4" />
                    Previous
                  </button>
                )}
                {siblingProducts.next && (
                  <button
                    onClick={() => handleNavigation("next")}
                    className="text-gray-600 hover:text-primary transition-colors flex items-center gap-1"
                  >
                    Next
                    <ChevronRight className="h-4 w-4" />
                  </button>
                )}
              </div>
            </div>

            {/* Rest of your existing JSX remains the same */}
            {/* Main Content */}
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-4 md:p-6 lg:p-8">
                {/* Image Gallery Section */}
                <div className="space-y-4">
                  <div className="relative w-full aspect-square md:aspect-auto md:h-[500px] rounded-xl overflow-hidden bg-gray-100">
                    {product.images?.length > 0 ? (
                      <div
                        className="relative w-full h-full cursor-pointer"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <div className="w-full h-full flex items-center justify-center bg-gray-50">
                          <img
                            src={product.images[selectedImageIndex]?.url}
                            alt={product.name}
                            className="max-w-full max-h-full object-contain"
                          />
                        </div>

                        {product.images.length > 1 && (
                          <>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePrevImage(e);
                              }}
                              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white text-gray-800 p-2 rounded-full shadow-lg transition-all z-10"
                            >
                              <ChevronLeft className="h-6 w-6" />
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleNextImage(e);
                              }}
                              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white text-gray-800 p-2 rounded-full shadow-lg transition-all z-10"
                            >
                              <ChevronRight className="h-6 w-6" />
                            </button>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center h-full bg-gray-100">
                        <span className="text-gray-400">
                          No image available
                        </span>
                      </div>
                    )}
                  </div>

                  {/* Thumbnail Grid */}
                  {product.images?.length > 1 && (
                    <div className="grid grid-cols-5 gap-2">
                      {product.images.map((image, index) => (
                        <button
                          key={image.publicId || index}
                          onClick={() => setSelectedImageIndex(index)}
                          className={`relative aspect-square rounded-lg overflow-hidden ${
                            selectedImageIndex === index
                              ? "ring-2 ring-primary"
                              : "hover:opacity-75"
                          } transition-all`}
                        >
                          <div className="w-full h-full flex items-center justify-center bg-gray-50">
                            <img
                              src={image.url}
                              alt={`${product.name || "Product"} ${index + 1}`}
                              className="max-w-full max-h-full object-contain"
                            />
                          </div>
                        </button>
                      ))}
                    </div>
                  )}
                </div>

                {/* Product Details Section */}
                <div className="space-y-6">
                  {product.categoryId && (
                    <Link
                      to={`/collection?category=${product.categoryId}`}
                      className="inline-block text-sm font-medium text-primary hover:underline"
                    >
                      {product.categoryName}
                    </Link>
                  )}

                  <div>
                    <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
                      {product.name}
                    </h1>
                    {/* Price Display Section */}
                    <div className="space-y-2">
                      {typeof product.originalPrice === "number" && (
                        <div className="flex items-baseline gap-2">
                          <span className="text-base md:text-lg text-gray-500">
                            Original Price:
                          </span>
                          <span
                            className={`text-xl md:text-2xl ${
                              product.discountPercentage
                                ? "text-gray-500 line-through"
                                : "text-gray-900"
                            }`}
                          >
                            {formatPrice(product.originalPrice)}
                          </span>
                        </div>
                      )}

                      {product.discountPercentage > 0 && (
                        <div className="flex items-baseline gap-2">
                          <span className="text-base md:text-lg text-gray-500">
                            Discount:
                          </span>
                          <span className="text-lg md:text-xl text-green-600 font-semibold">
                            {product.discountPercentage}% OFF
                          </span>
                        </div>
                      )}

                      {typeof product.discountedPrice === "number" && (
                        <div className="flex items-baseline gap-2">
                          <span className="text-base md:text-lg text-gray-500">
                            Final Price:
                          </span>
                          <span className="text-2xl md:text-3xl font-bold text-red-600">
                            {formatPrice(product.discountedPrice)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Product Description - Array Format */}
                  {sortedDescription.length > 0 && (
                    <div className="mt-6 md:mt-8">
                      <h3 className="text-lg font-semibold mb-4">
                        Product Details
                      </h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 md:p-6 bg-gray-50 rounded-lg">
                        {sortedDescription.map((field) => (
                          <div key={field.id} className="flex flex-col">
                            <dt className="text-sm font-medium text-gray-500">
                              {field.key}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {field.value || "-"}
                            </dd>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Key Features Section */}
                  <div className="mt-6 md:mt-8 p-4 md:p-6 bg-gray-50 rounded-lg">
                    <h3 className="text-lg font-semibold mb-4">Key Features</h3>
                    <ul className="space-y-2">
                      {KEY_FEATURES.map((feature, index) => (
                        <li key={index} className="flex items-center gap-2">
                          <span className="w-2 h-2 bg-primary rounded-full"></span>
                          <span className="text-sm md:text-base text-gray-700">
                            {feature}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Additional Information */}
                  <div className="mt-6 md:mt-8">
                    {/* Product Specifications */}
                    {product.specifications && (
                      <div className="mt-6">
                        <h3 className="text-lg font-semibold mb-4">
                          Specifications
                        </h3>
                        <div className="bg-gray-50 rounded-lg p-4 md:p-6">
                          <dl className="grid grid-cols-1 gap-4">
                            {Object.entries(product.specifications).map(
                              ([key, value]) => (
                                <div key={key} className="flex flex-col">
                                  <dt className="text-sm font-medium text-gray-500">
                                    {key}
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {value || "-"}
                                  </dd>
                                </div>
                              )
                            )}
                          </dl>
                        </div>
                      </div>
                    )}

                    {/* Additional Notes */}
                    {product.notes && (
                      <div className="mt-6">
                        <h3 className="text-lg font-semibold mb-4">Notes</h3>
                        <div className="bg-gray-50 rounded-lg p-4 md:p-6">
                          <p className="text-sm text-gray-700 whitespace-pre-line">
                            {product.notes}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
