import { useState, useRef, useEffect } from "react";
import { MapPin, Phone, Clock, Mail, Send } from "lucide-react";
import { db } from "../config/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
// Import your background image
import bgImage from "../images/bg.png";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [error, setError] = useState(null);
  const parallaxRef = useRef(null);

  // Add parallax effect
  useEffect(() => {
    const handleScroll = () => {
      if (parallaxRef.current) {
        const scrolled = window.scrollY;
        const rate = scrolled * 0.5;
        parallaxRef.current.style.transform = `translate3d(0, ${rate}px, 0)`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      // Add document to Firestore
      const queryData = {
        ...formData,
        timestamp: serverTimestamp(),
        status: "new", // You can use this to track query status
      };

      await addDoc(collection(db, "queries"), queryData);

      setSubmitStatus("success");
      // Reset form
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });

      // Clear success message after 3 seconds
      setTimeout(() => setSubmitStatus(null), 3000);
    } catch (err) {
      console.error("Error submitting form:", err);
      setError("Failed to submit form. Please try again.");
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const styles = `
    .parallax-container {
      position: relative;
      height: 70vh;
      overflow: hidden;
    }
    .parallax-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 120%;
      background-image: url(${bgImage});
      background-size: cover;
      background-position: center;
      will-change: transform;
    }
    .parallax-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7));
      z-index: 1;
    }
    .parallax-content {
      position: relative;
      height: 100%;
      z-index: 2;
    }
  `;

  return (
    <div className="min-h-screen bg-gray-50 pt-20">
      <style>{styles}</style>

      {/* Hero Section with Parallax */}
      <div className="parallax-container">
        <div ref={parallaxRef} className="parallax-image"></div>
        <div className="parallax-overlay"></div>
        <div className="parallax-content flex items-center">
          <div className="container-padding text-white">
            <h1 className="text-4xl font-bold mb-4 animate-slideUp">
              Contact Us
            </h1>
            <p
              className="text-lg opacity-90 max-w-2xl animate-slideUp"
              style={{ animationDelay: "0.1s" }}
            >
              We're here to help! Send us a message or visit our showroom to
              experience our collection in person.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Information Cards */}
      <div className="container-padding -mt-20 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[
            {
              icon: <MapPin className="w-6 h-6" />,
              title: "Visit Us",
              content:
                "No. 29 and 30, Tc Palya Main Road, Hoysala Nagar, Ramamurthy Nagar, Bangalore - 560016",
            },
            {
              icon: <Phone className="w-6 h-6" />,
              title: "Call Us",
              content: "+91 98440 54322",
            },
            {
              icon: <Clock className="w-6 h-6" />,
              title: "Business Hours",
              content: "10:00 AM - 9:00 PM (Daily)",
            },
            {
              icon: <Mail className="w-6 h-6" />,
              title: "Email Us",
              content: "malnadfurnitures@gmail.com",
            },
          ].map((item, index) => (
            <div
              key={item.title}
              className="bg-white rounded-lg shadow-lg p-6 transform hover:-translate-y-1 transition-all duration-300 animate-fadeIn"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="text-primary mb-4">{item.icon}</div>
              <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
              <p className="text-gray-600">{item.content}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Map and Form Section */}
      <div className="container-padding py-16">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Google Maps */}
          <div className="rounded-lg overflow-hidden shadow-lg h-[500px] animate-fadeIn">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.315775280793!2d77.66449907549976!3d13.015552087303947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae10df30f1e1ed%3A0x83cf394ffb0b3f81!2sMalnad%20Furnitures!5e0!3m2!1sen!2sin!4v1730363352805!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>

          {/* Contact Form */}
          <div
            className="bg-white rounded-lg shadow-lg p-8 animate-fadeIn"
            style={{ animationDelay: "0.2s" }}
          >
            <h2 className="text-2xl font-bold mb-6">Send us a Message</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Your Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
                <div>
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full flex items-center justify-center px-6 py-3 rounded-lg text-white font-medium transition-all duration-300 ${
                  isSubmitting
                    ? "bg-gray-400"
                    : "bg-primary hover:bg-primary/90"
                }`}
              >
                {isSubmitting ? (
                  "Sending..."
                ) : (
                  <>
                    Send Message
                    <Send className="w-4 h-4 ml-2" />
                  </>
                )}
              </button>
              {submitStatus === "success" && (
                <p className="text-green-600 text-center">
                  Message sent successfully!
                </p>
              )}
              {submitStatus === "error" && (
                <p className="text-red-600 text-center">{error}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
